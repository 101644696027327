import React from 'react';

import classes from './EntitySubscriptionList.module.scss';
import { LbHorizontalScrollContainer } from '../../../shared/components/LbHorizontalScrollContainer/LbHorizontalScrollContainer';
import { EmptyList } from '../../../shared/components/ui-components';
import { EntitySubscriptionInfo } from '../../interfaces/entitySubscription';
import { SubscriptionRow } from './SubscriptionRow/SubscriptionRow';
import { HeaderRow } from './HeaderRow/HeaderRow';
import {
  ChangeActivationStatus,
  ChangeDisplayToUserStatus,
  SortMethods,
} from './EntitySubscriptionList.settings';
import { TaxRateInfo } from '../../interfaces/tax';

type EntitySubscriptionListProps<
  TSubscriptionTypeKey extends string,
  THameKey extends string,
> = {
  subscriptionsList: EntitySubscriptionInfo[];
  changeActivationStatus: ChangeActivationStatus;
  changeDisplayToUserStatus: ChangeDisplayToUserStatus;
  sortMethods: SortMethods<TSubscriptionTypeKey, THameKey>;
  appliedTaxRateInfo?: TaxRateInfo | null;
  disabledEdit?: boolean;
  isSearchUsed?: boolean | null;
};

const EntitySubscriptionList = <
  TSubscriptionTypeKey extends string,
  THameKey extends string,
>({
  subscriptionsList,
  changeActivationStatus,
  changeDisplayToUserStatus,
  sortMethods,
  appliedTaxRateInfo,
  disabledEdit,
  isSearchUsed,
}: EntitySubscriptionListProps<TSubscriptionTypeKey, THameKey>) => {
  return (
    <LbHorizontalScrollContainer variant="inBox">
      <div className={classes.subscriptionTable}>
        <HeaderRow sortMethods={sortMethods} appliedTaxRateInfo={appliedTaxRateInfo} />
        {subscriptionsList.map((subscription) => (
          <SubscriptionRow
            key={subscription.id}
            subscriptionInfo={subscription}
            changeActivationStatus={changeActivationStatus}
            changeDisplayToUserStatus={changeDisplayToUserStatus}
            disabledEdit={disabledEdit}
          />
        ))}
      </div>
      <EmptyList
        show={!subscriptionsList.length}
        isSearch={isSearchUsed}
        description="There no subscriptions"
        className={classes.staticOnScroll}
        size="small"
      />
    </LbHorizontalScrollContainer>
  );
};

export { EntitySubscriptionList, EntitySubscriptionListProps };
