import moment, { Moment } from 'moment';
import {
  DatePickerSlotsComponents,
  DateValidationError,
  DateView,
} from '@mui/x-date-pickers';
import { UncapitalizeObjectKeys } from '@mui/x-date-pickers/internals';

import { FieldValues, Path, UseControllerProps } from 'react-hook-form';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded';

import { addValidateRules } from '../../../utils/reactHookFormUtils';
import { getFormattedDate, getFormattedToday } from '../../../utils/dateUtils';
import { isObject } from '../../../utils/jsUtils';
import {
  dateFormat,
  dateMonthFormat,
  localDataFormat,
  localMonthFormat,
} from '../../../constants/apiDateFormat';

export type DateErrorInfo = { date: Moment; error: DateValidationError };

export type DatePikerMode = 'day' | 'month';

type ModeSettingsInfo = {
  viewFormat: string;
  views: DateView[]; // order controls the order of the selection view
  placeholder: string;
  openTo: DateView;
  formFormat: string;
};

export const modeSettings: Record<DatePikerMode, ModeSettingsInfo> = {
  day: {
    viewFormat: localDataFormat,
    views: ['year', 'month', 'day'],
    placeholder: 'MMMM DD YYYY', // pattern shown in input by mui, we use MMM DD YYYY, check after update
    openTo: 'day',
    formFormat: dateFormat,
  },
  month: {
    viewFormat: localMonthFormat,
    views: ['year', 'month'],
    placeholder: 'MMMM YYYY', // pattern shown in input by mui, we use MMM YYYY, check after update
    openTo: 'month',
    formFormat: dateMonthFormat,
  },
};

export const iconComponents: UncapitalizeObjectKeys<DatePickerSlotsComponents<Moment>> = {
  openPickerIcon: CalendarTodayRoundedIcon,

  switchViewIcon: ArrowDropDownRoundedIcon,
};
/* 
  The date picker needs the date in the form of a moment, 
  but for the form it is more convenient to keep the date in the symbol of the required format, 
  for this the data is converted.
  
  The date picker independently validates the date entered by the user, 
  in order to transfer the error to the form at the time of the error, 
  an DateErrorInfo is transferred to the form, 
  which is validated in isValid.
*/

export const getFormValue = (
  date: Moment | null,
  format: string,
  error: string | null,
) => {
  if (error) {
    return { date, error };
  } else if (date) {
    return getFormattedDate(date, format);
  }
  return null;
};

export const getDatePickerValue = (value: string | DateErrorInfo, format: string) => {
  if (isObject(value)) {
    return moment(value.date, format);
  }
  if (value) {
    return moment(value, format);
  }
  return null;
};

export const addDataIsValidRule = <T extends FieldValues, TName extends Path<T>>(
  rules: UseControllerProps<T, TName>['rules'],
) => {
  const isValid = (value: string | DateErrorInfo) => {
    if (!value || !isObject(value)) {
      return true;
    }

    switch (value.error) {
      case 'disableFuture':
        return 'Please select a date in the past or today.';
      case 'maxDate':
        return 'The selected date is too far in the future.';
      case 'disablePast':
        return 'Please select a date in the future or today.';
      case 'minDate':
        return 'The selected date is too far in the past.';
      case 'shouldDisableDate':
        return 'This date cannot be selected.';
      default:
        return 'Invalid date selection.';
    }
  };

  return addValidateRules(rules, { isValid });
};

type GetDefaultValueArgs = {
  defaultValue?: Moment | string;
  format: string;
  defaultToday?: boolean;
};

export const getDefaultValue = ({
  defaultValue,
  format,
  defaultToday,
}: GetDefaultValueArgs) => {
  if (defaultToday) {
    return getFormattedToday(format);
  } else if (defaultValue) {
    return getFormattedDate(defaultValue, format);
  }

  return null;
};
