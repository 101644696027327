import React, { useEffect } from 'react';

import { Pagination } from '@mui/material';
import { observer } from 'mobx-react';

import classes from './ComplianceTable.module.scss';
import { ComplianceRow } from './ComplianceRow/ComplianceRow';
import { TitleRow } from './TitleRow/TitleRow';
import { complianceStore } from '../../../../../stores/complianceStore';
import { FilterRow } from './FilterRow/FilterRow';
import { EmptyList } from '../../../../../../shared/components/ui-components';

const ComplianceTable = observer(() => {
  const { complianceBoard, filterMethod, getComplianceBoard, setDefaultValues } =
    complianceStore;
  const {
    isShowPagination,
    isShowPlaceholder,
    isShowFilterRow,
    isSearchUsed,
    getPaginationProps,
  } = filterMethod;

  useEffect(() => {
    getComplianceBoard();
    return () => setDefaultValues();
  }, []);

  const paginationProps = getPaginationProps();

  return (
    <div className={classes.complianceTable}>
      <TitleRow />
      {isShowFilterRow && <FilterRow />}
      {complianceBoard.map((customerComplianceInfo) => (
        <ComplianceRow
          key={customerComplianceInfo.userId}
          customerComplianceInfo={customerComplianceInfo}
        />
      ))}
      <EmptyList
        show={isShowPlaceholder}
        isSearch={isSearchUsed}
        description="There no customers"
      />
      {isShowPagination && <Pagination {...paginationProps} />}
    </div>
  );
});

export { ComplianceTable };
