import { dateMonthFormat } from '../../../shared/constants/apiDateFormat';
import {
  getFormattedDate,
  getFormattedToday,
  getMonthsAgo,
} from '../../../shared/utils/dateUtils';

export type RevenueDateRangeFormValues = {
  firstMonth: string; // dateMonthFormat
  lastMonth: string; // dateMonthFormat
};

export type RevenueDateRangeFilterValues = {
  dateFrom: string; // dateTimeFormatWithTimezone
  dateTo: string; // dateTimeFormatWithTimezone
};

export const defaultFormValue: RevenueDateRangeFormValues = {
  firstMonth: getFormattedDate(getMonthsAgo(1), dateMonthFormat),
  lastMonth: getFormattedToday(dateMonthFormat),
};
