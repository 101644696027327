import { useEffect, useState } from 'react';

import moment, { Moment } from 'moment';

import { PerformanceSummary } from '../../../../../interfaces/performanceSummary';
import { detailedRevenueService } from '../../../../../services/detailedRevenueService';
import { getPercentages } from '../../../../../../shared/utils/mathUtils';
import { TotalAmountChartConfigItem } from '../TotalAmountChart.settings';
import { palette } from '../../../../../../shared/theme/palette';
import { RevenueDateRangeFilterValues } from '../../../DetailedRevenue.settings';

const defaultTotalAmountChartConfig: TotalAmountChartConfigItem[] = [
  {
    title: 'LifeBase Fees',
    color: palette.warning[400],
    amount: 0,
    percentage: 0,
    dbName: 'applicationFee',
  },
  {
    title: 'Stripe Fees',
    color: palette.violet[500],
    amount: 0,
    percentage: 0,
    dbName: 'stripeFee',
  },
  {
    title: 'Sales Tax',
    color: palette.info[400],
    amount: 0,
    percentage: 0,
    dbName: 'salesTax',
  },
  {
    title: 'Sales Tax (SaaS incl)',
    color: palette.error[400],
    amount: 0,
    percentage: 0,
    dbName: 'saasTax',
    description: 'Tax on LB Fees only',
  },
  {
    title: 'Entity NET',
    color: palette.success[400],
    amount: 0,
    percentage: 0,
    dbName: 'entityNET',
  },
];

type UseTotalAmountChartReturn = {
  performanceSummary: PerformanceSummary | null;
  totalAmountChartConfig: TotalAmountChartConfigItem[];
  totalAmount: number;
  loadTime: Moment | null;
};

// check correct work with filters
export const useTotalAmountChart = (
  entityId: number,
  dateRange: RevenueDateRangeFilterValues,
): UseTotalAmountChartReturn => {
  const [performanceSummary, setPerformanceSummary] = useState<PerformanceSummary | null>(
    null,
  );
  const [loadTime, setLoadTime] = useState<Moment | null>(null);

  const getPerformanceSummary = async () => {
    const data = await detailedRevenueService.getPerformanceSummary(entityId, {
      generalFilter: { ...dateRange },
    });
    setPerformanceSummary(data);
    setLoadTime(moment());
  };

  const totalAmount = performanceSummary?.totalAmount || 0;

  const getTotalAmountChartConfig = () => {
    if (!performanceSummary || totalAmount === 0) {
      return defaultTotalAmountChartConfig;
    }

    return [...defaultTotalAmountChartConfig].map((item) => {
      const amount = performanceSummary[item.dbName];
      const percentage = getPercentages(amount, totalAmount);

      return {
        ...item,
        amount,
        percentage,
      };
    });
  };

  useEffect(() => {
    getPerformanceSummary();
  }, [entityId, dateRange]);

  const totalAmountChartConfig = getTotalAmountChartConfig();

  return {
    performanceSummary,
    totalAmountChartConfig,
    totalAmount,
    loadTime,
  };
};
