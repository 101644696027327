import React from 'react';

import { angularize } from 'react-in-angularjs';
import { Pagination } from '@mui/material';

import { SystemProviders } from '../../../../../shared/providers';
import { TabTitleRow } from '../../components/TabTitleRow/TabTitleRow';
import { CustomerRow } from './CustomerRow/CustomerRow';
import { FiltersRow } from './FiltersRow/FiltersRow';
import { useInventoryAdjustmentTab } from './NgzInventoryAdjustmentTab.hook';
import { EmptyList } from '../../../../../shared/components/ui-components';

const NgzInventoryAdjustmentTab = () => {
  const {
    customers,
    isInitialList,
    pagesCount,
    currentPage,
    control,
    onSubmit,
    changeOrderHandler,
    pageChangeHandler,
    handleSubmit,
  } = useInventoryAdjustmentTab();

  const isShowPagination = pagesCount > 1;
  const isShowPlaceholder = !customers.length;

  return (
    <SystemProviders>
      <TabTitleRow />
      <FiltersRow
        control={control}
        onSubmit={handleSubmit(onSubmit)}
        changeOrderHandler={changeOrderHandler}
      />
      {customers.map((customer) => (
        <CustomerRow customerInfo={customer} key={customer.customerID} />
      ))}
      {isShowPagination && (
        <Pagination count={pagesCount} onChange={pageChangeHandler} page={currentPage} />
      )}
      <EmptyList
        isShow={isShowPlaceholder}
        isSearch={isInitialList}
        description="There no customers"
      />
    </SystemProviders>
  );
};

angularize(
  NgzInventoryAdjustmentTab,
  'ngzInventoryAdjustmentTab',
  angular.module('app.active-calendar'),
  {},
);

export default NgzInventoryAdjustmentTab;
