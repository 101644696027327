import React from 'react';

import EastRoundedIcon from '@mui/icons-material/EastRounded';
import { FieldValues, Path, useWatch } from 'react-hook-form';
import moment from 'moment';
import classNames from 'classnames';

import classes from './DateRangePicker.module.scss';
import { LbDatePicker, LbDatePickerProps } from '../LbDatePicker/LbDatePicker';

type BothPickerProps = 'variant' | 'size' | 'format' | 'openTo' | 'control' | 'mode';

type DateRangePickerProps<T extends FieldValues, TName extends Path<T>> = {
  startPickerProps: Omit<LbDatePickerProps<T, TName>, BothPickerProps>;
  endPickerProps: Omit<LbDatePickerProps<T, TName>, BothPickerProps>;
  className?: string;
  dataTestId?: string;
} & Pick<LbDatePickerProps<T, TName>, BothPickerProps>;

const DateRangePicker = <T extends FieldValues, TName extends Path<T>>({
  control,
  variant,
  size,
  format,
  openTo,
  startPickerProps,
  endPickerProps,
  className,
  dataTestId,
  mode,
}: DateRangePickerProps<T, TName>) => {
  const bothPickerProps = {
    control,
    variant,
    size,
    format,
    openTo,
    mode,
  };
  const startDate = useWatch({ name: startPickerProps.name, control });
  const endDate = useWatch({ name: endPickerProps.name, control });

  const rangeBoxClasses = classNames(classes.box, className);

  return (
    <div className={rangeBoxClasses} data-test-id={dataTestId}>
      <LbDatePicker
        {...bothPickerProps}
        {...startPickerProps}
        maxDate={moment(endDate, format)}
      />
      <EastRoundedIcon fontSize="small" />
      <LbDatePicker
        {...bothPickerProps}
        {...endPickerProps}
        minDate={moment(startDate, format)}
      />
    </div>
  );
};

export { DateRangePicker };
