import moment from 'moment';
import { dateFormat, localDataFormat } from '../constants/apiDateFormat';

// eslint-disable-next-line @typescript-eslint/default-param-last
function getFormattedDate(date, format = dateFormat, fromFormat) {
  return moment(date, fromFormat).format(format);
}

function getFormattedToday(format = dateFormat) {
  return getFormattedDate(undefined, format);
}
// only for date with timezone
function getFormattedLocalDate(date, format) {
  return moment(date).local().format(format);
}

function getTomorrow() {
  return moment().add(1, 'day');
}

function getDaysAgo(count) {
  return moment().subtract(count, 'day');
}

function getMonthsAgo(count) {
  return moment().subtract(count, 'month');
}

function isToday(date, format) {
  return moment(date, format).isSame(moment(), 'day');
}

function isTomorrow(date, format) {
  return moment(date, format).isSame(getTomorrow(), 'day');
}

function isInMonth(date, month) {
  return moment(month).isSame(date, 'month');
}

function getMonthLastDayOrToday(date) {
  return isInMonth(date) ? moment() : getMonthLastDay(date);
}

function getMonthLastDay(date, format) {
  return moment(date, format).endOf('month');
}

function getMonthFirstDay(date, format) {
  return moment(date, format).startOf('month');
}

function getMiddleDate(start, end, { format } = {}) {
  const startDate = moment(start, format);
  const endDate = moment(end, format);

  return startDate.add(endDate.diff(startDate) / 2);
}

function isDayAfterOrSame(day, startDate) {
  return isDayAfter(day, startDate) || isSameDay(day, startDate);
}

function isDayAfter(day, startDate) {
  return moment(day).isAfter(startDate);
}

function isDataBefore(day, endDate) {
  return moment(day).isBefore(endDate);
}

function isSameDay(day, comparisonDay) {
  return moment(day).isSame(comparisonDay, 'day');
}

function isFutureOrToday(day, format) {
  return moment().isSameOrBefore(moment(day, format), 'day');
}

function isFuture(day, format) {
  return moment().isBefore(moment(day, format), 'day');
}

function getHumanizeDay(date, format = localDataFormat) {
  const daysToDate = getDaysToDate(date);
  const defaultDayHandler = () => getFormattedDate(date, format);

  return getHumanizeDayByCount(daysToDate, defaultDayHandler);
}

function getDaysToDate(date) {
  if (!date) {
    return null;
  }

  const formattedDate = getFormattedDate(date);
  const formattedNow = getFormattedDate();

  const daysToDate = moment(formattedDate).diff(formattedNow, 'days', false);

  return daysToDate;
}

function getHumanizeDayByCount(daysToDateCount, defaultDayHandler) {
  switch (daysToDateCount) {
    case -1: {
      return 'Yesterday';
    }
    case 0: {
      return 'Today';
    }
    case 1: {
      return 'Tomorrow';
    }
    default: {
      return defaultDayHandler(daysToDateCount);
    }
  }
}

export {
  getFormattedDate,
  getFormattedToday,
  getFormattedLocalDate,
  getTomorrow,
  getDaysAgo,
  getMonthsAgo,
  isToday,
  isTomorrow,
  isInMonth,
  getMonthLastDayOrToday,
  getMonthLastDay,
  getMonthFirstDay,
  getMiddleDate,
  isDayAfterOrSame,
  isDayAfter,
  isDataBefore,
  isSameDay,
  isFutureOrToday,
  isFuture,
  getHumanizeDay,
  getDaysToDate,
  getHumanizeDayByCount,
};
