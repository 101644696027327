import React from 'react';

import classes from './HeaderRow.module.scss';
import {
  GridStickyGroup,
  GridTableCell,
  GridTableRow,
} from '../../../../../shared/components/table-components';
import { TitleWithDescription } from '../../../../../shared/components/text-formation-components';

const HeaderRow = () => {
  const titleGeneralProps = { titleFont: 'inherit', withoutGap: true } as const;

  return (
    <GridTableRow variant="unstyled" className={classes.header}>
      <GridStickyGroup>
        <GridTableCell>Name</GridTableCell>
      </GridStickyGroup>
      <GridTableCell>Subscription</GridTableCell>
      <GridTableCell>
        <TitleWithDescription
          {...titleGeneralProps}
          title="Total Amount"
          description="sales tax incl"
        />
      </GridTableCell>
      <GridTableCell>Status</GridTableCell>
      <GridTableCell>LifeBase Fees</GridTableCell>
      <GridTableCell>Stripe Fees</GridTableCell>
      <GridTableCell>
        <TitleWithDescription
          {...titleGeneralProps}
          title="Entity Deposit"
          description="sales tax incl"
        />
      </GridTableCell>
      <GridTableCell>Sales Tax</GridTableCell>
      <GridTableCell>Sales Tax (SaaS incl)</GridTableCell>

      <GridTableCell>
        <TitleWithDescription
          {...titleGeneralProps}
          title="Entity NET"
          description="no taxes"
        />
      </GridTableCell>
      <GridTableCell>Payment Date</GridTableCell>
      <GridTableCell justify="center">Invoice</GridTableCell>
    </GridTableRow>
  );
};

export { HeaderRow };
